* {
    box-sizing: border-box;
}

:root {
    --yellowLight: #fef6d8;
    --yellow: #f0b90b;
    --red: #fef6d8;
    --green: #fef6d8;
    --textBlack: #1e2329;
    --textGrey: #848e9c;
    --bgGrey: #fafafa;
    --bgBlack: #0b0e11;
}

tbody tr td:first-child>svg {
    width: 32px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    width: 5px;
    height: 4px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

html {
    line-height: 1.15;
    text-size-adjust: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: var(--bgGrey);
    -webkit-tap-highlight-color: transparent;
    background: radial-gradient(var(--yellowLight), transparent);
    height: 100%;

    #root {
        height: 100%;
    }
}

.main {
    max-width: 480px;
    margin: 0 auto;
    padding: 2em;
    background-color: #fff;
    color: var(--textBlack);
    text-align: center;
    position: relative;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.main h1 {
    font-size: 1.5em;
}

.main .table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0;
    padding: 1em 0;
    background: var(--bgGrey);
}

.main .table.single {
    grid-template-columns: 1fr;
    gap: 0;
}

.main .table.single .single {
    text-align: center !important;
}

.main .table .single .title {
    font-size: 0.75em;
    color: var(--textGrey);
}

.main .table .single .desc {
    font-size: 0.875em;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
}

.main .table .single .desc time {
    margin-right: 0.5em;
}

.main .table .copy {
    display: grid;
    place-items: center;
    cursor: pointer;
}

.main .table .single:nth-child(odd) {
    text-align: right;
}

.main .table .single:nth-child(even) {
    text-align: left;
}

.main .table svg {
    width: 1em;
    margin-left: 0.5em;
    color: var(--textGrey);
}

.main .logo {
    display: grid;
    place-items: center;
    padding-top: 1rem;
}

.main .logo svg {
    width: 25%;
}

.main .text {
    font-size: 0.875em;
    word-break: break-word;
}

.main .qrcode {
    display: grid;
    grid-template-columns: auto;
    gap: 1em;
    max-width: 310px
}

.main .qrcode .image {
    display: grid;
    place-items: center;
}

.main .qrcode>.text {
    color: var(--textGrey);
}

.main .warning {
    padding: 1em;
    background: var(--yellowLight);
}

.main .address {
    background: var(--bgGrey);
    padding: 1em;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 1em;
    align-items: center;
    color: var(--textBlack);
}

.main .address.walletTag {
    grid-template-columns: auto auto auto;
}

.main .address.walletTag > .text:nth-child(2) {
    font-weight: 700;
}

.main .address .copy {
    cursor: pointer;
    display: grid;
}

.main .address svg {
    width: 1em;
    color: var(--textGrey);
}

.main .warning .text {
    color: var(--textBlack);
}

.main .head>svg {
    width: 60px;
    margin: 1em auto;
}

.logo-header>svg {
    width: auto !important;
    height: 35px !important;
    margin: 0px !important;
}

hr {
    margin: 2em 0;
    height: 1px;
    background: var(--bgGrey);
    border: none;
}

.centered {
    display: grid;
    place-items: center;
    margin: 1em 0;
}

.main .site-button {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1em;
    justify-content: center;
}

.main .site-button.single {
    grid-template-columns: 1fr !important;
}

.main .site-button div {
    display: inline-block;
    padding: 0.75em 3em;
    border-radius: 4px;
    background-color: #fcd535;
    color: var(--textBlack);
    text-decoration: none;
    font-size: 0.875em;
    font-weight: 500;
    cursor: pointer;
}

.main .site-button div:first-child {
    background: #eaecef;
}

.main .site-button div:only-child {
    background-color: #fcd535;
}

.main .withdraw div:first-child {
    background: #fcd535 !important;
}

.main .withdraw div:last-child {
    background-color: #eaecef !important;
}

.BTC-logo>svg {
    height: 30px !important;
}

@media screen and (max-width: 480px) {
    body {
        padding: 0;
    }

    .main {
        width: 100%;
        position: relative;
        padding: 1em 2em;
        padding-top: 4em;
        min-height: 100vh;
        margin: 0;
    }

    .main .qrcode {
        // display: block;
        justify-content: center;
    }

    .main .table {
        // grid-template-columns: auto;
        gap: 1em;
        padding: 1em;
    }

    .main .table .single:nth-child(n) {
        text-align: left;
    }

    // .main .warning {
    //     margin: 1em 0;
    // }
    .main .address {
        grid-template-columns: auto auto;
        // margin: 1em 0;
    }

    .main .site-button {
        grid-template-columns: 1fr 1fr;
        gap: 0.5em;
    }

    .main .warning .text,
    .main .table .single .desc,
    .main .text {
        font-size: 0.75em;
    }

    .main .site-button div {
        padding: 0.75em 2.5em;
    }

    .main h1 {
        font-size: 1.25em;
    }

    .main .logo svg {
        width: 40%;
    }

    hr {
        margin: 1em 0;
    }

    .table .single:last-child {
        display: grid;
    }

    .table .single:last-child .desc>span {
        display: none;
    }

    .main .table .single .desc time {
        margin: 0;
        font-size: 0.875em;
    }

    .first-info label {
        grid-template-columns: 1fr;
    }

    .first-info input {
        text-align: center;
    }

    .text-area {
        max-width: 358px !important;
    }

    .logo-header {
        margin-top: 1rem;
    }

    .select-money .single.active {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 320px) {
    .main .site-button div {
        padding: 0.75em 1.5em;
    }
}

.select-money .grid-money {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    // display: grid;
    // gap: 0;
    // grid-template-columns: auto auto;
    // border: solid 1px #EEE;
    // border-radius: 5px;
}

.select-money .single {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1em;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    padding: 1em;
    background: #fff;
    border: solid 1px #d1b75f;
}

.select-money .single.active {
    background: var(--yellowLight);
}

.select-coin:hover {
    color: #F0B90B;
}

.select-money .single .title {
    font-size: 0.875em;
    white-space: nowrap;
    margin: 0.25em 0;
}

.select-money .single .desc {
    font-size: 0.75em;
    color: #555;
}

.select-money svg {
    width: 1.75em;
    height: 1.75em;
    margin: 0 auto;
}

.first-info {
    margin: 2em 0;
    display: grid;
    grid-template-columns: auto;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
}

.first-info .label {
    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #eee;
}

.first-info .inputs {
    display: grid;
    grid-template-columns: auto;
    gap: 0.5em;
    margin-top: 1em;
}

.first-info .label .title {
    background: #eee;
    color: #000;
    padding: 1em;
    height: 100%;
    font-size: 0.875em;
    font-weight: 500;
    display: grid;
    place-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.first-info input {
    background: none;
    border: none;
    padding: 1em 1em;
    outline: none;
    font-size: 16px;
    width: auto;
}

.first-info input[disabled] {
    background: #f9f9f9;
    color: #fff;
}

.first-info .price {
    background: var(--yellowLight);
    font-size: 0.875em;
    padding: 1em;
    border-radius: 4px;
}

.first-info .price .title {
    font-weight: 500;
}

.first-info p {
    color: var(--textGrey);
    font-size: 0.875em;
}

.react-confirm-alert-button-group button:first-child {
    background: #fcd535 !important;
    color: black;
}

.change-letter {
    min-height: 38px;
    text-align: center
}

.qr-area {
    display: grid;
    place-items: center
}

.css-vubbuv {
    font-size: 1, 25em !important;
}

.css-9l3uo3 {
    font-size: 13px !important;
}

.coins-modal {
    position: absolute;
    top: 0em;
    left: 0em;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    border-radius: 5px;
    padding: 2em;
    text-align: left;
    line-height: 1;
}

.coins-modal .content {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.coins-modal .content .top {
    padding: 1em;
}

.coins-modal .content .top .title {
    font-weight: 500;
    font-size: 1.25em;
}

.coins-modal .content .top .desc {
    margin-top: 1em;
    color: #707A8A;
    font-size: .875em;
}

.coins-modal .content .current {
    padding: 1em;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1em;
    align-items: center;
    background-color: #FEF6D8;
    color: #C99400;
    justify-content: flex-start;
}

.coins-modal .content .current .coin {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.coins-modal .content svg {
    width: 32px;
    height: 32px;
}

.coins-modal .content .current .coin .title {
    white-space: nowrap;
    font-weight: 500;
    margin-bottom: 2px;
}

.coins-modal .content .current .coin .desc {
    color: #707A8A;
    font-size: .75em;
}

.coins-modal .close-modal {
    position: absolute;
    top: 0.25em;
    right: 2em;
    cursor: pointer;
}

.coins-modal .close-modal svg {
    width: 1em;
}

.coins-modal .list>.title {
    padding: 1em;
    font-weight: 500;
}

.coins-modal .list .items {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: flex-start;
    align-items: stretch;
    background: var(--bgGrey);
}

.coins-modal .list .items .single {
    padding: 0.5em 1em;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.5em;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    position: relative;
    border-top: 1px solid #eee;
}

.coins-modal .content svg {
    width: 32px;
    height: 32px;
}

.coins-modal .list .items .single .title {
    white-space: nowrap;
    font-weight: 500;
    margin-bottom: 2px;
}

.coins-modal .list .items .single .desc {
    color: #707A8A;
    font-size: .75em;
}

.withdraw-grid {
    grid-template-columns: none !important;
}

.custom-menu-item-icon>svg,
.custom-menu-item-icon>img {
    width: 20px;
    height: 20px;
}

.text-area {
    max-width: 310px;
    margin-top: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}