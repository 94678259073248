.m-0 {
    margin: 0;
}

.pt-0 {
    padding-top: 0rem;
}

.pt-1 {
    padding-top: 1rem;
}

.pb-0 {
    padding-bottom: 0rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pb-c {
    padding-bottom: 0.5rem;
}

.mr-1 {
    margin-right: 1rem;
}

.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-right {
    justify-content: right;
}

.justify-left {
    justify-content: left;
}

.border-10 {
    border-radius: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.loading-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #ffffff45;
    z-index: 9999;
}

.custom-cardcontent {
    padding: 1rem !important;
}